<template lang="pug">
  transition(name="fade")
    a(href="#top", v-if="active", v-bind:class="{'is-active':active}").v-smooth_scroll.button
</template>

<style lang="scss" scoped>
.button {
  display: block;
  width: mod_vw(106);
  height: mod_vw(106);
  background: #c597e1;
  border-radius: 50%;
  position: absolute;
  bottom: mod_vw(40);
  right: mod_vw(20);
  opacity: 0.7;

  @include mq() {
    width: 70px;
    height: 70px;
    bottom: 40px;
    right: calc((100% - 1000px) / 2);
    bottom: 100px;
  }

  &:before {
    content: '';
    display: block;
    width: mod_vw(22);
    height: mod_vw(22);
    margin-top: mod_vw(5);
    border-top: mod_vw(4) solid #fff;
    border-right: mod_vw(4) solid #fff;
    box-sizing: border-box;
    border-radius: mod_vw(2);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);

    @include mq() {
      width: 15px;
      height: 15px;
      margin-top: 5px;
      border-width: 3px;
      border-radius: 2px;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>


<script>
import Vue from 'vue';
import SmoothScroll from 'smooth-scroll';

export default {
  data: function() {
    return {
      active: false,
      scrollThreshold: 300,
    }
  },
  methods: {
    setScrollEvent: function() {
      let scrollTicking = false;
      if(scrollTicking) return false;
      document.addEventListener('scroll', e => {
        requestAnimationFrame(e => {
          this.toggleSTTbutton();
          scrollTicking = true;
        });
      }, {
        passive: true,
      });
    },
    toggleSTTbutton: function() {
      if(window.scrollY > this.scrollThreshold) {
        this.$data.active = true;
      } else {
        this.$data.active = false;
      }
    },
  },
  mounted: function() {
    this.setScrollEvent();

    new SmoothScroll('.v-smooth_scroll', {
      speed: 500,
      speedAsDuration: true,
    });
  }
}
</script>
