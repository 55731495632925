var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.active
      ? _c("a", {
          staticClass: "v-smooth_scroll button",
          class: { "is-active": _vm.active },
          attrs: { href: "#top" }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }