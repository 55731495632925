import $ from 'jquery';
import Vue from 'vue';
import SmoothScroll from 'smooth-scroll';

import ScrollTopButton from '../vue/ScrollTopButton.vue';

document.addEventListener('DOMContentLoaded', e => {
  // smooth scroll
  new SmoothScroll('.js-smooth_scroll', {
    speed: 500,
    speedAsDuration: true,
    offset: window.innerWidth * 0.14,
  });

  $('.js-smooth_scroll').on('click', () => {
    $('.js-header_nav__btn').removeClass('is-active');
    $('.js-header_nav__inr').removeClass('is-active');
  });

  // sp header nav
  (() => {
    $('.js-header_nav__btn').on('click', function() {
      $(this).toggleClass('is-active');
      $(this).siblings('.js-header_nav__inr').toggleClass('is-active');
    });
  })();

  // ページ内ナビゲーション
  Vue.component('scroll-top-button', ScrollTopButton);
  const targetDOM = document.querySelector('#v-stb');
  targetDOM && new Vue({ el: targetDOM });
});
